/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

$custom-dark-palette: (
 50: #F8F7FF,
 100: #F0F0FD,
 200: #E6E5F2,
 300: #D5D5E1,
 400: #B1B1BD,
 500:#91909c,
 600:#696964,
 700: #555560,
 800: #373741,
 900: #171720,
 contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white
 )
);

$custom-green-palette: (
 50: #E3F5EC,
 100: #BAE6D1,
 200: #8DD5B3,
 300: #58C596,
 400: #1EB980,
 500:#00AC6A,
 600:#009D5F,
 700: #008B52,
 800: #007A46,
 900: #005B30,
 contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white
 )
);

// Define a light theme
$light-primary: mat.define-palette($custom-dark-palette);
$light-accent: mat.define-palette($custom-green-palette);
$my-warn: mat.define-palette(mat.$red-palette);
$light-theme: mat.define-light-theme((
 color: (
   primary: $light-primary,
   accent: $light-accent,
   warn: $my-warn
 )
));

// Define a dark theme
$dark-primary: mat.define-palette($custom-dark-palette, 400);
$dark-accent: mat.define-palette($custom-dark-palette);
$dark-theme: mat.define-dark-theme((
 color: (
   primary: $dark-primary,
   accent: $dark-accent,
   warn: $my-warn
 )
));

// Apply the dark theme by default
// @include mat.core-theme($dark-theme);
// @include mat.button-theme($dark-theme);
 @include mat.all-component-themes($dark-theme);

// Apply the light theme only when the `.my-light-theme` CSS class is applied
// to an ancestor element of the components (such as `body`).
.my-light-theme {
 @include mat.all-component-themes($light-theme);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; 

  // todo: need move component itself 
  .warn-snackbar {
    color: red;
  }
  .success-snackbar {
    color: #00FF00;
  }
}
